import { Client, JoinOptions, Room } from 'colyseus.js';
import { SchemaConstructor } from 'colyseus.js/lib/serializer/SchemaSerializer';
import { GatewayClientMessage, GatewayClientMessageType } from './GatewayClientProtocol';
import { Deferred } from './Utils';
import WebSocket from 'ws';
import { Buffer } from 'buffer/';

export interface IZepetoClientEvents {
    onMatchMake: (time: number) => void;
    onConsumeReservation: (time: number) => void;
}

export abstract class ZepetoClient extends Client {

    public responses: { [key: string]: Deferred } = {};
    static messageId = 0;

    static toIPCMessage(data: any, messageId?: number) {
        return new GatewayClientMessage({ messageType: GatewayClientMessageType.IPC, messageId: messageId, data: Buffer.from(JSON.stringify(data)) }).toBuffer();
    }
    
    protected onMessage(buffer: WebSocket.Data) {
      

        let values: any = buffer;
        if (buffer instanceof ArrayBuffer) {
            values = Buffer.from(buffer);
        }

        let clientMessage = GatewayClientMessage.parse(values);
        if (clientMessage) {
            
            if (clientMessage.messageType == GatewayClientMessageType.PING) {
                this.send(new GatewayClientMessage({ messageId: GatewayClientMessageType.PONG }).toBuffer());
            } else if (clientMessage.messageType == GatewayClientMessageType.IPC && clientMessage.data) {
                if (this.responses[clientMessage.messageId]) {
                    let res = JSON.parse(clientMessage.data.toString());
                    this.responses[clientMessage.messageId].resolve(res);
                    delete this.responses[clientMessage.messageId];
                }
            } else if (clientMessage.messageType == GatewayClientMessageType.MESSAGE && clientMessage.data) {
                let res = JSON.parse(clientMessage.data.toString());
                console.log(res);
            } else if (clientMessage.messageType == GatewayClientMessageType.ERROR && clientMessage.data) {
                let res = JSON.parse(clientMessage.data.toString());
                console.log(res);
            }
        }
    }

    protected abstract send(buffer: Buffer);


    dispose() {

    }
}