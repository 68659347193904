import { Room } from 'colyseus.js';
import { SchemaConstructor } from 'colyseus.js/lib/serializer/SchemaSerializer';
import WebSocket from 'ws';
import { Deferred } from './Utils';
import { ZepetoClient } from './ZepetoClient';
import { Buffer } from 'buffer/';



class ZepetoWebSocketRoom<T> extends Room<T> {


    public websocket: WebSocket;
    //public connection: Connection;

    constructor(websocket: WebSocket, name: string, rootSchema: SchemaConstructor<T>) {
        super(name, rootSchema);
        this.websocket = websocket;
        this.onMessageHandlers.events['i17'] = [];
        this.onMessageHandlers.events['i18'] = []; 
        this.onMessageHandlers.events['i15'] = [];  
        this.onMessageHandlers.events['i19'] = [];
    }

    public connect(endpoint: string) {
        let websocket = this.websocket;
        //this.connection = new ZepetoConnection(websocket);

        (this as any).connection = {
            events: {
            },
            send: this.websocket.send.bind(this.websocket),
            connect(url: string): void {
                websocket.onopen = this.events.onopen;
                websocket.onmessage = this.events.onmessage;
                websocket.onclose = this.events.onclose;
                websocket.onerror = this.events.onerror;
                websocket.send(ZepetoClient.toIPCMessage({ messageType: 'Connect', path: url }));
            },
            close(code?: number, reason?: string): void {
                //console.log("close")
            },
        };

        this.connection.events.onmessage = this.onMessageCallback.bind(this);
        this.connection.events.onclose = (e) => {
            if (!this.hasJoined) {
                console.warn(`Room connection was closed unexpectedly (${e.code}): ${e.reason}`);
                this.onError.invoke(e.code, e.reason);
                return;
            }
            this.onLeave.invoke(e.code);
            (this as any).destroy();
        };
        this.connection.events.onerror = (e) => {
            console.warn(`Room, onError (${e.code}): ${e.reason}`);
            this.onError.invoke(e.code, e.reason);
        };
        this.connection.connect(this.id);   
    }
    
}
export class ZepetoWebSocketClient extends ZepetoClient {

    public websocket: WebSocket;
    public responses: { [key: string]: Deferred } = {};
    static messageId = 0;

    constructor(websocket: WebSocket) {
        super();
        this.websocket = websocket;
        this.websocket.on('message', (buffer: Buffer) => {
            this.onMessage(Buffer.from(buffer));
        });
    }

    public createRoom<T>(roomName: string, rootSchema?: SchemaConstructor<T>): Room<T> {
        return new ZepetoWebSocketRoom(this.websocket, roomName, rootSchema);
    }

    public send(buffer: Buffer) {
        this.websocket.send(buffer);
    }

    public dispose() {
        this.websocket.close();        
    }

}
