import { Buffer } from 'buffer/';
export enum GatewayClientMessageType {
    IPC = 0,
    PING = 1,
    PONG = 2,
    AUTH = 3,
    MESSAGE = 4,
    ERROR = 5
}
export class GatewayClientMessage {
    static PREFIX = "$GATEWAY$";

    public messageType: GatewayClientMessageType;
    public messageId: number;
    public data?: Buffer;

    get size() {
        return GatewayClientMessage.PREFIX.length              // PREFIX
            + 1                                             // MESSAGE_TYPE
            + 2                                             // MESSAGE_ID
            + (this.data ? this.data.length : 0)            // DATA
    }
    constructor(base?: Partial<GatewayClientMessage>) {
        if (base) Object.assign(this, base);
    }

    protected encode(offset?: { offset: number }): Buffer {
        var buffer: Buffer = Buffer.alloc(this.size) as Buffer;
        if (!offset) offset = { offset: 0 };


        buffer.write(GatewayClientMessage.PREFIX, offset.offset, GatewayClientMessage.PREFIX.length, "utf-8");
        offset.offset += GatewayClientMessage.PREFIX.length;
        buffer.writeUInt8(this.messageType || 0, offset.offset);
        offset.offset += 1;
        buffer.writeUInt16LE(this.messageId ?? 0, offset.offset);
        offset.offset += 2;


        if (this.data && this.data.length > 0) {
            var result = this.data.copy(buffer, offset.offset, 0, this.data.length);
            offset.offset += this.data.length;
        }
        return buffer;

    }

    protected decode(buffer: Buffer, offset?: { offset: number }): this {

        if (!offset) offset = { offset: 0 };

        var prefix = buffer.toString("utf-8", offset.offset, offset.offset + GatewayClientMessage.PREFIX.length);
        if (prefix != GatewayClientMessage.PREFIX) return null;

        offset.offset += GatewayClientMessage.PREFIX.length;

        this.messageType = buffer.readUInt8(offset.offset);
        offset.offset += 1;

        this.messageId = buffer.readUInt16LE(offset.offset);
        offset.offset += 2;


        var dataLength = buffer.length - offset.offset;
        if (dataLength > 0) this.data = Buffer.from(buffer.buffer, buffer.byteOffset + offset.offset, dataLength);
        offset.offset += dataLength;
        return this;
    }

    static isGatewayClientMessage(buffer: Buffer) {
        if (buffer.length <= GatewayClientMessage.PREFIX.length) return false;
        var prefix = buffer.toString("utf-8", 0, GatewayClientMessage.PREFIX.length);
        return prefix == GatewayClientMessage.PREFIX;
    }

    static parse(buffer: Buffer): GatewayClientMessage {

        try {
            if (!buffer || buffer.length == 0) return null;
            var message = new GatewayClientMessage();
            return message.decode(buffer);
        } catch (error) {

        }
        return null;
    }

    toBuffer(): Buffer {
        return this.encode();
    }

}
