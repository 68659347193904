import {ZepetoClient} from "./ZepetoClient";
import {JoinOptions, Room} from "colyseus.js";
import {SchemaConstructor} from "colyseus.js/lib/serializer/SchemaSerializer";
import WebSocket from "ws";
import { Buffer } from 'buffer/';

class ServerError extends Error {
    private code: number;
    constructor(code, message) {
        super(message);
        this.name = "ServerError";
        this.code = code;
    }
}

export class ZepetoHttpClient extends ZepetoClient {

    private parseURL: URL;
    private host: string;
    private port: number;
    private room: Room;

    private _authToken?: string;
    public set authToken(value: string) {
        this._authToken = value;
    }

    public get authToken() {
        return this._authToken;
    }

    private _authProvider: string = 'ZEPETO';
    public set authProvider(value: string) {
        this._authProvider = value;
    }

    public get authProvider() {
        return this._authProvider;
    }

    public websocket: WebSocket;


    constructor(endPoint: string) {
        let socketUrl = endPoint.startsWith('http') ? endPoint.replace(/^http/, 'ws') : endPoint.replace(/^https/, 'wss');
        super(socketUrl);
        this.parseURL = new URL(socketUrl);
        this.host = this.parseURL.host;
    }

    protected send(buffer: Buffer) {
        this.websocket.send(buffer);
    }

    async createMatchMakeRequest<T>(method: string, roomName: string, options?: JoinOptions, rootSchema?: SchemaConstructor<T>): Promise<Room<T>> {
       
        console.log('[LOG] createMatchMakeRequest');
        const request = await fetch(`${this.parseURL.protocol === 'ws:' ? 'http' : 'https'}://${this.parseURL.host}/matchmake/${method}/${roomName}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'authorization': this.authToken.toString(),
                'x-application': this._authProvider
            },
            body: JSON.stringify(options)
        });

        if (request.status !== 200) {
            throw Error(`service server internal error (${request.status})`);
        }

        const json = await request.json();
        if(!json.room?.name)
            json.room['name'] = json.room?.processId;
        
        console.log('[LOG] consumeSeatReservation');
        return await this.consumeSeatReservation(json, rootSchema, this.authToken.toString());
    }

    consumeSeatReservation<T>(response: any, rootSchema?: SchemaConstructor<T>, authorization?: string): Promise<Room<T>> {
        const room = this.createRoom(response.room.name, rootSchema);
        room.id = response.room.roomId;
        room.sessionId = response.sessionId;
        room.connect(`${this.endpoint}/join`);
        
        this.websocket = room.connection.transport['ws'];
        this.websocket.onopen = () => {
            console.log('[LOG] OpenSocket');
            this.websocket.send(JSON.stringify({
                headers: {
                    'x-application': this._authProvider,
                    authorization
                },
                body: {
                    roomId: response.room.roomId,
                    processId: response.room.processId,
                    sessionId: response.sessionId
                }
            }))
        }

        return new Promise((resolve, reject) => {
            const onError = (code, message) => reject(new ServerError(code, message));
            room.onError.once(onError);
            room['onJoin'].once(() => {
                room.onError.remove(onError);
                resolve(room);
            });
        });
    }
}

